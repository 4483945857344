@charset "UTF-8";
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 300;
  src: url("../documents/fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../documents/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("../documents/fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("../documents/fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("../documents/fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("../documents/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-display: block; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-fw {
  width: 1.28571em;
  text-align: center; }

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center; }
  .fa-li.fa-lg {
    left: -1.85714em; }

.fa-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eee;
  border-radius: .1em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right {
  margin-left: .3em; }

/* Deprecated as of 4.4.0 */
.pull-right {
  float: right; }

.pull-left {
  float: left; }

.fa.pull-left {
  margin-right: .3em; }

.fa.pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none; }

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle; }

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-glass:before {
  content: ""; }

.fa-music:before {
  content: ""; }

.fa-search:before {
  content: ""; }

.fa-envelope-o:before {
  content: ""; }

.fa-heart:before {
  content: ""; }

.fa-star:before {
  content: ""; }

.fa-star-o:before {
  content: ""; }

.fa-user:before {
  content: ""; }

.fa-film:before {
  content: ""; }

.fa-th-large:before {
  content: ""; }

.fa-th:before {
  content: ""; }

.fa-th-list:before {
  content: ""; }

.fa-check:before {
  content: ""; }

.fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: ""; }

.fa-search-plus:before {
  content: ""; }

.fa-search-minus:before {
  content: ""; }

.fa-power-off:before {
  content: ""; }

.fa-signal:before {
  content: ""; }

.fa-gear:before,
.fa-cog:before {
  content: ""; }

.fa-trash-o:before {
  content: ""; }

.fa-home:before {
  content: ""; }

.fa-file-o:before {
  content: ""; }

.fa-clock-o:before {
  content: ""; }

.fa-road:before {
  content: ""; }

.fa-download:before {
  content: ""; }

.fa-arrow-circle-o-down:before {
  content: ""; }

.fa-arrow-circle-o-up:before {
  content: ""; }

.fa-inbox:before {
  content: ""; }

.fa-play-circle-o:before {
  content: ""; }

.fa-rotate-right:before,
.fa-repeat:before {
  content: ""; }

.fa-refresh:before {
  content: ""; }

.fa-list-alt:before {
  content: ""; }

.fa-lock:before {
  content: ""; }

.fa-flag:before {
  content: ""; }

.fa-headphones:before {
  content: ""; }

.fa-volume-off:before {
  content: ""; }

.fa-volume-down:before {
  content: ""; }

.fa-volume-up:before {
  content: ""; }

.fa-qrcode:before {
  content: ""; }

.fa-barcode:before {
  content: ""; }

.fa-tag:before {
  content: ""; }

.fa-tags:before {
  content: ""; }

.fa-book:before {
  content: ""; }

.fa-bookmark:before {
  content: ""; }

.fa-print:before {
  content: ""; }

.fa-camera:before {
  content: ""; }

.fa-font:before {
  content: ""; }

.fa-bold:before {
  content: ""; }

.fa-italic:before {
  content: ""; }

.fa-text-height:before {
  content: ""; }

.fa-text-width:before {
  content: ""; }

.fa-align-left:before {
  content: ""; }

.fa-align-center:before {
  content: ""; }

.fa-align-right:before {
  content: ""; }

.fa-align-justify:before {
  content: ""; }

.fa-list:before {
  content: ""; }

.fa-dedent:before,
.fa-outdent:before {
  content: ""; }

.fa-indent:before {
  content: ""; }

.fa-video-camera:before {
  content: ""; }

.fa-photo:before,
.fa-image:before,
.fa-picture-o:before {
  content: ""; }

.fa-pencil:before {
  content: ""; }

.fa-map-marker:before {
  content: ""; }

.fa-adjust:before {
  content: ""; }

.fa-tint:before {
  content: ""; }

.fa-edit:before,
.fa-pencil-square-o:before {
  content: ""; }

.fa-share-square-o:before {
  content: ""; }

.fa-check-square-o:before {
  content: ""; }

.fa-arrows:before {
  content: ""; }

.fa-step-backward:before {
  content: ""; }

.fa-fast-backward:before {
  content: ""; }

.fa-backward:before {
  content: ""; }

.fa-play:before {
  content: ""; }

.fa-pause:before {
  content: ""; }

.fa-stop:before {
  content: ""; }

.fa-forward:before {
  content: ""; }

.fa-fast-forward:before {
  content: ""; }

.fa-step-forward:before {
  content: ""; }

.fa-eject:before {
  content: ""; }

.fa-chevron-left:before {
  content: ""; }

.fa-chevron-right:before {
  content: ""; }

.fa-plus-circle:before {
  content: ""; }

.fa-minus-circle:before {
  content: ""; }

.fa-times-circle:before {
  content: ""; }

.fa-check-circle:before {
  content: ""; }

.fa-question-circle:before {
  content: ""; }

.fa-info-circle:before {
  content: ""; }

.fa-crosshairs:before {
  content: ""; }

.fa-times-circle-o:before {
  content: ""; }

.fa-check-circle-o:before {
  content: ""; }

.fa-ban:before {
  content: ""; }

.fa-arrow-left:before {
  content: ""; }

.fa-arrow-right:before {
  content: ""; }

.fa-arrow-up:before {
  content: ""; }

.fa-arrow-down:before {
  content: ""; }

.fa-mail-forward:before,
.fa-share:before {
  content: ""; }

.fa-expand:before {
  content: ""; }

.fa-compress:before {
  content: ""; }

.fa-plus:before {
  content: ""; }

.fa-minus:before {
  content: ""; }

.fa-asterisk:before {
  content: ""; }

.fa-exclamation-circle:before {
  content: ""; }

.fa-gift:before {
  content: ""; }

.fa-leaf:before {
  content: ""; }

.fa-fire:before {
  content: ""; }

.fa-eye:before {
  content: ""; }

.fa-eye-slash:before {
  content: ""; }

.fa-warning:before,
.fa-exclamation-triangle:before {
  content: ""; }

.fa-plane:before {
  content: ""; }

.fa-calendar:before {
  content: ""; }

.fa-random:before {
  content: ""; }

.fa-comment:before {
  content: ""; }

.fa-magnet:before {
  content: ""; }

.fa-chevron-up:before {
  content: ""; }

.fa-chevron-down:before {
  content: ""; }

.fa-retweet:before {
  content: ""; }

.fa-shopping-cart:before {
  content: ""; }

.fa-folder:before {
  content: ""; }

.fa-folder-open:before {
  content: ""; }

.fa-arrows-v:before {
  content: ""; }

.fa-arrows-h:before {
  content: ""; }

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: ""; }

.fa-twitter-square:before {
  content: ""; }

.fa-facebook-square:before {
  content: ""; }

.fa-camera-retro:before {
  content: ""; }

.fa-key:before {
  content: ""; }

.fa-gears:before,
.fa-cogs:before {
  content: ""; }

.fa-comments:before {
  content: ""; }

.fa-thumbs-o-up:before {
  content: ""; }

.fa-thumbs-o-down:before {
  content: ""; }

.fa-star-half:before {
  content: ""; }

.fa-heart-o:before {
  content: ""; }

.fa-sign-out:before {
  content: ""; }

.fa-linkedin-square:before {
  content: ""; }

.fa-thumb-tack:before {
  content: ""; }

.fa-external-link:before {
  content: ""; }

.fa-sign-in:before {
  content: ""; }

.fa-trophy:before {
  content: ""; }

.fa-github-square:before {
  content: ""; }

.fa-upload:before {
  content: ""; }

.fa-lemon-o:before {
  content: ""; }

.fa-phone:before {
  content: ""; }

.fa-square-o:before {
  content: ""; }

.fa-bookmark-o:before {
  content: ""; }

.fa-phone-square:before {
  content: ""; }

.fa-twitter:before {
  content: ""; }

.fa-facebook-f:before,
.fa-facebook:before {
  content: ""; }

.fa-github:before {
  content: ""; }

.fa-unlock:before {
  content: ""; }

.fa-credit-card:before {
  content: ""; }

.fa-feed:before,
.fa-rss:before {
  content: ""; }

.fa-hdd-o:before {
  content: ""; }

.fa-bullhorn:before {
  content: ""; }

.fa-bell:before {
  content: ""; }

.fa-certificate:before {
  content: ""; }

.fa-hand-o-right:before {
  content: ""; }

.fa-hand-o-left:before {
  content: ""; }

.fa-hand-o-up:before {
  content: ""; }

.fa-hand-o-down:before {
  content: ""; }

.fa-arrow-circle-left:before {
  content: ""; }

.fa-arrow-circle-right:before {
  content: ""; }

.fa-arrow-circle-up:before {
  content: ""; }

.fa-arrow-circle-down:before {
  content: ""; }

.fa-globe:before {
  content: ""; }

.fa-wrench:before {
  content: ""; }

.fa-tasks:before {
  content: ""; }

.fa-filter:before {
  content: ""; }

.fa-briefcase:before {
  content: ""; }

.fa-arrows-alt:before {
  content: ""; }

.fa-group:before,
.fa-users:before {
  content: ""; }

.fa-chain:before,
.fa-link:before {
  content: ""; }

.fa-cloud:before {
  content: ""; }

.fa-flask:before {
  content: ""; }

.fa-cut:before,
.fa-scissors:before {
  content: ""; }

.fa-copy:before,
.fa-files-o:before {
  content: ""; }

.fa-paperclip:before {
  content: ""; }

.fa-save:before,
.fa-floppy-o:before {
  content: ""; }

.fa-square:before {
  content: ""; }

.fa-navicon:before,
.fa-reorder:before,
.fa-bars:before {
  content: ""; }

.fa-list-ul:before {
  content: ""; }

.fa-list-ol:before {
  content: ""; }

.fa-strikethrough:before {
  content: ""; }

.fa-underline:before {
  content: ""; }

.fa-table:before {
  content: ""; }

.fa-magic:before {
  content: ""; }

.fa-truck:before {
  content: ""; }

.fa-pinterest:before {
  content: ""; }

.fa-pinterest-square:before {
  content: ""; }

.fa-google-plus-square:before {
  content: ""; }

.fa-google-plus:before {
  content: ""; }

.fa-money:before {
  content: ""; }

.fa-caret-down:before {
  content: ""; }

.fa-caret-up:before {
  content: ""; }

.fa-caret-left:before {
  content: ""; }

.fa-caret-right:before {
  content: ""; }

.fa-columns:before {
  content: ""; }

.fa-unsorted:before,
.fa-sort:before {
  content: ""; }

.fa-sort-down:before,
.fa-sort-desc:before {
  content: ""; }

.fa-sort-up:before,
.fa-sort-asc:before {
  content: ""; }

.fa-envelope:before {
  content: ""; }

.fa-linkedin:before {
  content: ""; }

.fa-rotate-left:before,
.fa-undo:before {
  content: ""; }

.fa-legal:before,
.fa-gavel:before {
  content: ""; }

.fa-dashboard:before,
.fa-tachometer:before {
  content: ""; }

.fa-comment-o:before {
  content: ""; }

.fa-comments-o:before {
  content: ""; }

.fa-flash:before,
.fa-bolt:before {
  content: ""; }

.fa-sitemap:before {
  content: ""; }

.fa-umbrella:before {
  content: ""; }

.fa-paste:before,
.fa-clipboard:before {
  content: ""; }

.fa-lightbulb-o:before {
  content: ""; }

.fa-exchange:before {
  content: ""; }

.fa-cloud-download:before {
  content: ""; }

.fa-cloud-upload:before {
  content: ""; }

.fa-user-md:before {
  content: ""; }

.fa-stethoscope:before {
  content: ""; }

.fa-suitcase:before {
  content: ""; }

.fa-bell-o:before {
  content: ""; }

.fa-coffee:before {
  content: ""; }

.fa-cutlery:before {
  content: ""; }

.fa-file-text-o:before {
  content: ""; }

.fa-building-o:before {
  content: ""; }

.fa-hospital-o:before {
  content: ""; }

.fa-ambulance:before {
  content: ""; }

.fa-medkit:before {
  content: ""; }

.fa-fighter-jet:before {
  content: ""; }

.fa-beer:before {
  content: ""; }

.fa-h-square:before {
  content: ""; }

.fa-plus-square:before {
  content: ""; }

.fa-angle-double-left:before {
  content: ""; }

.fa-angle-double-right:before {
  content: ""; }

.fa-angle-double-up:before {
  content: ""; }

.fa-angle-double-down:before {
  content: ""; }

.fa-angle-left:before {
  content: ""; }

.fa-angle-right:before {
  content: ""; }

.fa-angle-up:before {
  content: ""; }

.fa-angle-down:before {
  content: ""; }

.fa-desktop:before {
  content: ""; }

.fa-laptop:before {
  content: ""; }

.fa-tablet:before {
  content: ""; }

.fa-mobile-phone:before,
.fa-mobile:before {
  content: ""; }

.fa-circle-o:before {
  content: ""; }

.fa-quote-left:before {
  content: ""; }

.fa-quote-right:before {
  content: ""; }

.fa-spinner:before {
  content: ""; }

.fa-circle:before, .fa.none:before {
  content: ""; }

.fa-mail-reply:before,
.fa-reply:before {
  content: ""; }

.fa-github-alt:before {
  content: ""; }

.fa-folder-o:before {
  content: ""; }

.fa-folder-open-o:before {
  content: ""; }

.fa-smile-o:before {
  content: ""; }

.fa-frown-o:before {
  content: ""; }

.fa-meh-o:before {
  content: ""; }

.fa-gamepad:before {
  content: ""; }

.fa-keyboard-o:before {
  content: ""; }

.fa-flag-o:before {
  content: ""; }

.fa-flag-checkered:before {
  content: ""; }

.fa-terminal:before {
  content: ""; }

.fa-code:before {
  content: ""; }

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: ""; }

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: ""; }

.fa-location-arrow:before {
  content: ""; }

.fa-crop:before {
  content: ""; }

.fa-code-fork:before {
  content: ""; }

.fa-unlink:before,
.fa-chain-broken:before {
  content: ""; }

.fa-question:before {
  content: ""; }

.fa-info:before {
  content: ""; }

.fa-exclamation:before {
  content: ""; }

.fa-superscript:before {
  content: ""; }

.fa-subscript:before {
  content: ""; }

.fa-eraser:before {
  content: ""; }

.fa-puzzle-piece:before {
  content: ""; }

.fa-microphone:before {
  content: ""; }

.fa-microphone-slash:before {
  content: ""; }

.fa-shield:before {
  content: ""; }

.fa-calendar-o:before {
  content: ""; }

.fa-fire-extinguisher:before {
  content: ""; }

.fa-rocket:before {
  content: ""; }

.fa-maxcdn:before {
  content: ""; }

.fa-chevron-circle-left:before {
  content: ""; }

.fa-chevron-circle-right:before {
  content: ""; }

.fa-chevron-circle-up:before {
  content: ""; }

.fa-chevron-circle-down:before {
  content: ""; }

.fa-html5:before {
  content: ""; }

.fa-css3:before {
  content: ""; }

.fa-anchor:before {
  content: ""; }

.fa-unlock-alt:before {
  content: ""; }

.fa-bullseye:before {
  content: ""; }

.fa-ellipsis-h:before {
  content: ""; }

.fa-ellipsis-v:before {
  content: ""; }

.fa-rss-square:before {
  content: ""; }

.fa-play-circle:before {
  content: ""; }

.fa-ticket:before {
  content: ""; }

.fa-minus-square:before {
  content: ""; }

.fa-minus-square-o:before {
  content: ""; }

.fa-level-up:before {
  content: ""; }

.fa-level-down:before {
  content: ""; }

.fa-check-square:before {
  content: ""; }

.fa-pencil-square:before {
  content: ""; }

.fa-external-link-square:before {
  content: ""; }

.fa-share-square:before {
  content: ""; }

.fa-compass:before {
  content: ""; }

.fa-toggle-down:before,
.fa-caret-square-o-down:before {
  content: ""; }

.fa-toggle-up:before,
.fa-caret-square-o-up:before {
  content: ""; }

.fa-toggle-right:before,
.fa-caret-square-o-right:before {
  content: ""; }

.fa-euro:before,
.fa-eur:before {
  content: ""; }

.fa-gbp:before {
  content: ""; }

.fa-dollar:before,
.fa-usd:before {
  content: ""; }

.fa-rupee:before,
.fa-inr:before {
  content: ""; }

.fa-cny:before,
.fa-rmb:before,
.fa-yen:before,
.fa-jpy:before {
  content: ""; }

.fa-ruble:before,
.fa-rouble:before,
.fa-rub:before {
  content: ""; }

.fa-won:before,
.fa-krw:before {
  content: ""; }

.fa-bitcoin:before,
.fa-btc:before {
  content: ""; }

.fa-file:before {
  content: ""; }

.fa-file-text:before {
  content: ""; }

.fa-sort-alpha-asc:before {
  content: ""; }

.fa-sort-alpha-desc:before {
  content: ""; }

.fa-sort-amount-asc:before {
  content: ""; }

.fa-sort-amount-desc:before {
  content: ""; }

.fa-sort-numeric-asc:before {
  content: ""; }

.fa-sort-numeric-desc:before {
  content: ""; }

.fa-thumbs-up:before {
  content: ""; }

.fa-thumbs-down:before {
  content: ""; }

.fa-youtube-square:before {
  content: ""; }

.fa-youtube:before {
  content: ""; }

.fa-xing:before {
  content: ""; }

.fa-xing-square:before {
  content: ""; }

.fa-youtube-play:before {
  content: ""; }

.fa-dropbox:before {
  content: ""; }

.fa-stack-overflow:before {
  content: ""; }

.fa-instagram:before {
  content: ""; }

.fa-flickr:before {
  content: ""; }

.fa-adn:before {
  content: ""; }

.fa-bitbucket:before {
  content: ""; }

.fa-bitbucket-square:before {
  content: ""; }

.fa-tumblr:before {
  content: ""; }

.fa-tumblr-square:before {
  content: ""; }

.fa-long-arrow-down:before {
  content: ""; }

.fa-long-arrow-up:before {
  content: ""; }

.fa-long-arrow-left:before {
  content: ""; }

.fa-long-arrow-right:before {
  content: ""; }

.fa-apple:before {
  content: ""; }

.fa-windows:before {
  content: ""; }

.fa-android:before {
  content: ""; }

.fa-linux:before {
  content: ""; }

.fa-dribbble:before {
  content: ""; }

.fa-skype:before {
  content: ""; }

.fa-foursquare:before {
  content: ""; }

.fa-trello:before {
  content: ""; }

.fa-female:before {
  content: ""; }

.fa-male:before {
  content: ""; }

.fa-gittip:before,
.fa-gratipay:before {
  content: ""; }

.fa-sun-o:before {
  content: ""; }

.fa-moon-o:before {
  content: ""; }

.fa-archive:before {
  content: ""; }

.fa-bug:before {
  content: ""; }

.fa-vk:before {
  content: ""; }

.fa-weibo:before {
  content: ""; }

.fa-renren:before {
  content: ""; }

.fa-pagelines:before {
  content: ""; }

.fa-stack-exchange:before {
  content: ""; }

.fa-arrow-circle-o-right:before {
  content: ""; }

.fa-arrow-circle-o-left:before {
  content: ""; }

.fa-toggle-left:before,
.fa-caret-square-o-left:before {
  content: ""; }

.fa-dot-circle-o:before {
  content: ""; }

.fa-wheelchair:before {
  content: ""; }

.fa-vimeo-square:before {
  content: ""; }

.fa-turkish-lira:before,
.fa-try:before {
  content: ""; }

.fa-plus-square-o:before {
  content: ""; }

.fa-space-shuttle:before {
  content: ""; }

.fa-slack:before {
  content: ""; }

.fa-envelope-square:before {
  content: ""; }

.fa-wordpress:before {
  content: ""; }

.fa-openid:before {
  content: ""; }

.fa-institution:before,
.fa-bank:before,
.fa-university:before {
  content: ""; }

.fa-mortar-board:before,
.fa-graduation-cap:before {
  content: ""; }

.fa-yahoo:before {
  content: ""; }

.fa-google:before {
  content: ""; }

.fa-reddit:before {
  content: ""; }

.fa-reddit-square:before {
  content: ""; }

.fa-stumbleupon-circle:before {
  content: ""; }

.fa-stumbleupon:before {
  content: ""; }

.fa-delicious:before {
  content: ""; }

.fa-digg:before {
  content: ""; }

.fa-pied-piper-pp:before {
  content: ""; }

.fa-pied-piper-alt:before {
  content: ""; }

.fa-drupal:before {
  content: ""; }

.fa-joomla:before {
  content: ""; }

.fa-language:before {
  content: ""; }

.fa-fax:before {
  content: ""; }

.fa-building:before {
  content: ""; }

.fa-child:before {
  content: ""; }

.fa-paw:before {
  content: ""; }

.fa-spoon:before {
  content: ""; }

.fa-cube:before {
  content: ""; }

.fa-cubes:before {
  content: ""; }

.fa-behance:before {
  content: ""; }

.fa-behance-square:before {
  content: ""; }

.fa-steam:before {
  content: ""; }

.fa-steam-square:before {
  content: ""; }

.fa-recycle:before {
  content: ""; }

.fa-automobile:before,
.fa-car:before {
  content: ""; }

.fa-cab:before,
.fa-taxi:before {
  content: ""; }

.fa-tree:before {
  content: ""; }

.fa-spotify:before {
  content: ""; }

.fa-deviantart:before {
  content: ""; }

.fa-soundcloud:before {
  content: ""; }

.fa-database:before {
  content: ""; }

.fa-file-pdf-o:before {
  content: ""; }

.fa-file-word-o:before {
  content: ""; }

.fa-file-excel-o:before {
  content: ""; }

.fa-file-powerpoint-o:before {
  content: ""; }

.fa-file-photo-o:before,
.fa-file-picture-o:before,
.fa-file-image-o:before {
  content: ""; }

.fa-file-zip-o:before,
.fa-file-archive-o:before {
  content: ""; }

.fa-file-sound-o:before,
.fa-file-audio-o:before {
  content: ""; }

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: ""; }

.fa-file-code-o:before {
  content: ""; }

.fa-vine:before {
  content: ""; }

.fa-codepen:before {
  content: ""; }

.fa-jsfiddle:before {
  content: ""; }

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-saver:before,
.fa-support:before,
.fa-life-ring:before {
  content: ""; }

.fa-circle-o-notch:before {
  content: ""; }

.fa-ra:before,
.fa-resistance:before,
.fa-rebel:before {
  content: ""; }

.fa-ge:before,
.fa-empire:before {
  content: ""; }

.fa-git-square:before {
  content: ""; }

.fa-git:before {
  content: ""; }

.fa-y-combinator-square:before,
.fa-yc-square:before,
.fa-hacker-news:before {
  content: ""; }

.fa-tencent-weibo:before {
  content: ""; }

.fa-qq:before {
  content: ""; }

.fa-wechat:before,
.fa-weixin:before {
  content: ""; }

.fa-send:before,
.fa-paper-plane:before {
  content: ""; }

.fa-send-o:before,
.fa-paper-plane-o:before {
  content: ""; }

.fa-history:before {
  content: ""; }

.fa-circle-thin:before {
  content: ""; }

.fa-header:before {
  content: ""; }

.fa-paragraph:before {
  content: ""; }

.fa-sliders:before {
  content: ""; }

.fa-share-alt:before {
  content: ""; }

.fa-share-alt-square:before {
  content: ""; }

.fa-bomb:before {
  content: ""; }

.fa-soccer-ball-o:before,
.fa-futbol-o:before {
  content: ""; }

.fa-tty:before {
  content: ""; }

.fa-binoculars:before {
  content: ""; }

.fa-plug:before {
  content: ""; }

.fa-slideshare:before {
  content: ""; }

.fa-twitch:before {
  content: ""; }

.fa-yelp:before {
  content: ""; }

.fa-newspaper-o:before {
  content: ""; }

.fa-wifi:before {
  content: ""; }

.fa-calculator:before {
  content: ""; }

.fa-paypal:before {
  content: ""; }

.fa-google-wallet:before {
  content: ""; }

.fa-cc-visa:before {
  content: ""; }

.fa-cc-mastercard:before {
  content: ""; }

.fa-cc-discover:before {
  content: ""; }

.fa-cc-amex:before {
  content: ""; }

.fa-cc-paypal:before {
  content: ""; }

.fa-cc-stripe:before {
  content: ""; }

.fa-bell-slash:before {
  content: ""; }

.fa-bell-slash-o:before {
  content: ""; }

.fa-trash:before {
  content: ""; }

.fa-copyright:before {
  content: ""; }

.fa-at:before {
  content: ""; }

.fa-eyedropper:before {
  content: ""; }

.fa-paint-brush:before {
  content: ""; }

.fa-birthday-cake:before {
  content: ""; }

.fa-area-chart:before {
  content: ""; }

.fa-pie-chart:before {
  content: ""; }

.fa-line-chart:before {
  content: ""; }

.fa-lastfm:before {
  content: ""; }

.fa-lastfm-square:before {
  content: ""; }

.fa-toggle-off:before {
  content: ""; }

.fa-toggle-on:before {
  content: ""; }

.fa-bicycle:before {
  content: ""; }

.fa-bus:before {
  content: ""; }

.fa-ioxhost:before {
  content: ""; }

.fa-angellist:before {
  content: ""; }

.fa-cc:before {
  content: ""; }

.fa-shekel:before,
.fa-sheqel:before,
.fa-ils:before {
  content: ""; }

.fa-meanpath:before {
  content: ""; }

.fa-buysellads:before {
  content: ""; }

.fa-connectdevelop:before {
  content: ""; }

.fa-dashcube:before {
  content: ""; }

.fa-forumbee:before {
  content: ""; }

.fa-leanpub:before {
  content: ""; }

.fa-sellsy:before {
  content: ""; }

.fa-shirtsinbulk:before {
  content: ""; }

.fa-simplybuilt:before {
  content: ""; }

.fa-skyatlas:before {
  content: ""; }

.fa-cart-plus:before {
  content: ""; }

.fa-cart-arrow-down:before {
  content: ""; }

.fa-diamond:before {
  content: ""; }

.fa-ship:before {
  content: ""; }

.fa-user-secret:before {
  content: ""; }

.fa-motorcycle:before {
  content: ""; }

.fa-street-view:before {
  content: ""; }

.fa-heartbeat:before {
  content: ""; }

.fa-venus:before {
  content: ""; }

.fa-mars:before {
  content: ""; }

.fa-mercury:before {
  content: ""; }

.fa-intersex:before,
.fa-transgender:before {
  content: ""; }

.fa-transgender-alt:before {
  content: ""; }

.fa-venus-double:before {
  content: ""; }

.fa-mars-double:before {
  content: ""; }

.fa-venus-mars:before {
  content: ""; }

.fa-mars-stroke:before {
  content: ""; }

.fa-mars-stroke-v:before {
  content: ""; }

.fa-mars-stroke-h:before {
  content: ""; }

.fa-neuter:before {
  content: ""; }

.fa-genderless:before {
  content: ""; }

.fa-facebook-official:before {
  content: ""; }

.fa-pinterest-p:before {
  content: ""; }

.fa-whatsapp:before {
  content: ""; }

.fa-server:before {
  content: ""; }

.fa-user-plus:before {
  content: ""; }

.fa-user-times:before {
  content: ""; }

.fa-hotel:before,
.fa-bed:before {
  content: ""; }

.fa-viacoin:before {
  content: ""; }

.fa-train:before {
  content: ""; }

.fa-subway:before {
  content: ""; }

.fa-medium:before {
  content: ""; }

.fa-yc:before,
.fa-y-combinator:before {
  content: ""; }

.fa-optin-monster:before {
  content: ""; }

.fa-opencart:before {
  content: ""; }

.fa-expeditedssl:before {
  content: ""; }

.fa-battery-4:before,
.fa-battery:before,
.fa-battery-full:before {
  content: ""; }

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: ""; }

.fa-battery-2:before,
.fa-battery-half:before {
  content: ""; }

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: ""; }

.fa-battery-0:before,
.fa-battery-empty:before {
  content: ""; }

.fa-mouse-pointer:before {
  content: ""; }

.fa-i-cursor:before {
  content: ""; }

.fa-object-group:before {
  content: ""; }

.fa-object-ungroup:before {
  content: ""; }

.fa-sticky-note:before {
  content: ""; }

.fa-sticky-note-o:before {
  content: ""; }

.fa-cc-jcb:before {
  content: ""; }

.fa-cc-diners-club:before {
  content: ""; }

.fa-clone:before {
  content: ""; }

.fa-balance-scale:before {
  content: ""; }

.fa-hourglass-o:before {
  content: ""; }

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: ""; }

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: ""; }

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: ""; }

.fa-hourglass:before {
  content: ""; }

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: ""; }

.fa-hand-stop-o:before,
.fa-hand-paper-o:before {
  content: ""; }

.fa-hand-scissors-o:before {
  content: ""; }

.fa-hand-lizard-o:before {
  content: ""; }

.fa-hand-spock-o:before {
  content: ""; }

.fa-hand-pointer-o:before {
  content: ""; }

.fa-hand-peace-o:before {
  content: ""; }

.fa-trademark:before {
  content: ""; }

.fa-registered:before {
  content: ""; }

.fa-creative-commons:before {
  content: ""; }

.fa-gg:before {
  content: ""; }

.fa-gg-circle:before {
  content: ""; }

.fa-tripadvisor:before {
  content: ""; }

.fa-odnoklassniki:before {
  content: ""; }

.fa-odnoklassniki-square:before {
  content: ""; }

.fa-get-pocket:before {
  content: ""; }

.fa-wikipedia-w:before {
  content: ""; }

.fa-safari:before {
  content: ""; }

.fa-chrome:before {
  content: ""; }

.fa-firefox:before {
  content: ""; }

.fa-opera:before {
  content: ""; }

.fa-internet-explorer:before {
  content: ""; }

.fa-tv:before,
.fa-television:before {
  content: ""; }

.fa-contao:before {
  content: ""; }

.fa-500px:before {
  content: ""; }

.fa-amazon:before {
  content: ""; }

.fa-calendar-plus-o:before {
  content: ""; }

.fa-calendar-minus-o:before {
  content: ""; }

.fa-calendar-times-o:before {
  content: ""; }

.fa-calendar-check-o:before {
  content: ""; }

.fa-industry:before {
  content: ""; }

.fa-map-pin:before {
  content: ""; }

.fa-map-signs:before {
  content: ""; }

.fa-map-o:before {
  content: ""; }

.fa-map:before {
  content: ""; }

.fa-commenting:before {
  content: ""; }

.fa-commenting-o:before {
  content: ""; }

.fa-houzz:before {
  content: ""; }

.fa-vimeo:before {
  content: ""; }

.fa-black-tie:before {
  content: ""; }

.fa-fonticons:before {
  content: ""; }

.fa-reddit-alien:before {
  content: ""; }

.fa-edge:before {
  content: ""; }

.fa-credit-card-alt:before {
  content: ""; }

.fa-codiepie:before {
  content: ""; }

.fa-modx:before {
  content: ""; }

.fa-fort-awesome:before {
  content: ""; }

.fa-usb:before {
  content: ""; }

.fa-product-hunt:before {
  content: ""; }

.fa-mixcloud:before {
  content: ""; }

.fa-scribd:before {
  content: ""; }

.fa-pause-circle:before {
  content: ""; }

.fa-pause-circle-o:before {
  content: ""; }

.fa-stop-circle:before {
  content: ""; }

.fa-stop-circle-o:before {
  content: ""; }

.fa-shopping-bag:before {
  content: ""; }

.fa-shopping-basket:before {
  content: ""; }

.fa-hashtag:before {
  content: ""; }

.fa-bluetooth:before {
  content: ""; }

.fa-bluetooth-b:before {
  content: ""; }

.fa-percent:before {
  content: ""; }

.fa-gitlab:before {
  content: ""; }

.fa-wpbeginner:before {
  content: ""; }

.fa-wpforms:before {
  content: ""; }

.fa-envira:before {
  content: ""; }

.fa-universal-access:before {
  content: ""; }

.fa-wheelchair-alt:before {
  content: ""; }

.fa-question-circle-o:before {
  content: ""; }

.fa-blind:before {
  content: ""; }

.fa-audio-description:before {
  content: ""; }

.fa-volume-control-phone:before {
  content: ""; }

.fa-braille:before {
  content: ""; }

.fa-assistive-listening-systems:before {
  content: ""; }

.fa-asl-interpreting:before,
.fa-american-sign-language-interpreting:before {
  content: ""; }

.fa-deafness:before,
.fa-hard-of-hearing:before,
.fa-deaf:before {
  content: ""; }

.fa-glide:before {
  content: ""; }

.fa-glide-g:before {
  content: ""; }

.fa-signing:before,
.fa-sign-language:before {
  content: ""; }

.fa-low-vision:before {
  content: ""; }

.fa-viadeo:before {
  content: ""; }

.fa-viadeo-square:before {
  content: ""; }

.fa-snapchat:before {
  content: ""; }

.fa-snapchat-ghost:before {
  content: ""; }

.fa-snapchat-square:before {
  content: ""; }

.fa-pied-piper:before {
  content: ""; }

.fa-first-order:before {
  content: ""; }

.fa-yoast:before {
  content: ""; }

.fa-themeisle:before {
  content: ""; }

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: ""; }

.fa-fa:before,
.fa-font-awesome:before {
  content: ""; }

.fa-handshake-o:before {
  content: ""; }

.fa-envelope-open:before {
  content: ""; }

.fa-envelope-open-o:before {
  content: ""; }

.fa-linode:before {
  content: ""; }

.fa-address-book:before {
  content: ""; }

.fa-address-book-o:before {
  content: ""; }

.fa-vcard:before,
.fa-address-card:before {
  content: ""; }

.fa-vcard-o:before,
.fa-address-card-o:before {
  content: ""; }

.fa-user-circle:before {
  content: ""; }

.fa-user-circle-o:before {
  content: ""; }

.fa-user-o:before {
  content: ""; }

.fa-id-badge:before {
  content: ""; }

.fa-drivers-license:before,
.fa-id-card:before {
  content: ""; }

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: ""; }

.fa-quora:before {
  content: ""; }

.fa-free-code-camp:before {
  content: ""; }

.fa-telegram:before {
  content: ""; }

.fa-thermometer-4:before,
.fa-thermometer:before,
.fa-thermometer-full:before {
  content: ""; }

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: ""; }

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: ""; }

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: ""; }

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: ""; }

.fa-shower:before {
  content: ""; }

.fa-bathtub:before,
.fa-s15:before,
.fa-bath:before {
  content: ""; }

.fa-podcast:before {
  content: ""; }

.fa-window-maximize:before {
  content: ""; }

.fa-window-minimize:before {
  content: ""; }

.fa-window-restore:before {
  content: ""; }

.fa-times-rectangle:before,
.fa-window-close:before {
  content: ""; }

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: ""; }

.fa-bandcamp:before {
  content: ""; }

.fa-grav:before {
  content: ""; }

.fa-etsy:before {
  content: ""; }

.fa-imdb:before {
  content: ""; }

.fa-ravelry:before {
  content: ""; }

.fa-eercast:before {
  content: ""; }

.fa-microchip:before {
  content: ""; }

.fa-snowflake-o:before {
  content: ""; }

.fa-superpowers:before {
  content: ""; }

.fa-wpexplorer:before {
  content: ""; }

.fa-meetup:before {
  content: ""; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(../images/flags/4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(../images/flags/1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../images/flags/4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(../images/flags/1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../images/flags/4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(../images/flags/1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../images/flags/4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(../images/flags/1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../images/flags/4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(../images/flags/1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../images/flags/4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(../images/flags/1x1/al.svg); }

.flag-icon-am {
  background-image: url(../images/flags/4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(../images/flags/1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../images/flags/4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(../images/flags/1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../images/flags/4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(../images/flags/1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../images/flags/4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(../images/flags/1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../images/flags/4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(../images/flags/1x1/as.svg); }

.flag-icon-at {
  background-image: url(../images/flags/4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(../images/flags/1x1/at.svg); }

.flag-icon-au {
  background-image: url(../images/flags/4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(../images/flags/1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../images/flags/4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(../images/flags/1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../images/flags/4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(../images/flags/1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../images/flags/4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(../images/flags/1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../images/flags/4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(../images/flags/1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../images/flags/4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(../images/flags/1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../images/flags/4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(../images/flags/1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../images/flags/4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(../images/flags/1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../images/flags/4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(../images/flags/1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../images/flags/4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(../images/flags/1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../images/flags/4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(../images/flags/1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../images/flags/4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(../images/flags/1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../images/flags/4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(../images/flags/1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../images/flags/4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(../images/flags/1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../images/flags/4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(../images/flags/1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../images/flags/4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(../images/flags/1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../images/flags/4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(../images/flags/1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../images/flags/4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(../images/flags/1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../images/flags/4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(../images/flags/1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../images/flags/4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(../images/flags/1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../images/flags/4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(../images/flags/1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../images/flags/4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(../images/flags/1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../images/flags/4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(../images/flags/1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../images/flags/4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(../images/flags/1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../images/flags/4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(../images/flags/1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../images/flags/4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(../images/flags/1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../images/flags/4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(../images/flags/1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../images/flags/4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(../images/flags/1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../images/flags/4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(../images/flags/1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../images/flags/4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(../images/flags/1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../images/flags/4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(../images/flags/1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../images/flags/4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(../images/flags/1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../images/flags/4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(../images/flags/1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../images/flags/4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(../images/flags/1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../images/flags/4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(../images/flags/1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../images/flags/4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(../images/flags/1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../images/flags/4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(../images/flags/1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../images/flags/4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(../images/flags/1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../images/flags/4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(../images/flags/1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../images/flags/4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(../images/flags/1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../images/flags/4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(../images/flags/1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../images/flags/4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(../images/flags/1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../images/flags/4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(../images/flags/1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../images/flags/4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(../images/flags/1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../images/flags/4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(../images/flags/1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../images/flags/4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(../images/flags/1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../images/flags/4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(../images/flags/1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../images/flags/4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(../images/flags/1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../images/flags/4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(../images/flags/1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../images/flags/4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(../images/flags/1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../images/flags/4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(../images/flags/1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../images/flags/4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(../images/flags/1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../images/flags/4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(../images/flags/1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../images/flags/4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(../images/flags/1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../images/flags/4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(../images/flags/1x1/er.svg); }

.flag-icon-es {
  background-image: url(../images/flags/4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(../images/flags/1x1/es.svg); }

.flag-icon-et {
  background-image: url(../images/flags/4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(../images/flags/1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../images/flags/4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(../images/flags/1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../images/flags/4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(../images/flags/1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../images/flags/4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(../images/flags/1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../images/flags/4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(../images/flags/1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../images/flags/4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(../images/flags/1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../images/flags/4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(../images/flags/1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../images/flags/4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(../images/flags/1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../images/flags/4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(../images/flags/1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../images/flags/4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(../images/flags/1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../images/flags/4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(../images/flags/1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../images/flags/4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(../images/flags/1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../images/flags/4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(../images/flags/1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../images/flags/4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(../images/flags/1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../images/flags/4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(../images/flags/1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../images/flags/4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(../images/flags/1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../images/flags/4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(../images/flags/1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../images/flags/4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(../images/flags/1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../images/flags/4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(../images/flags/1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../images/flags/4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(../images/flags/1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../images/flags/4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(../images/flags/1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../images/flags/4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(../images/flags/1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../images/flags/4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(../images/flags/1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../images/flags/4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(../images/flags/1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../images/flags/4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(../images/flags/1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../images/flags/4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(../images/flags/1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../images/flags/4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(../images/flags/1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../images/flags/4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(../images/flags/1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../images/flags/4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(../images/flags/1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../images/flags/4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(../images/flags/1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../images/flags/4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(../images/flags/1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../images/flags/4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(../images/flags/1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../images/flags/4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(../images/flags/1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../images/flags/4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(../images/flags/1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../images/flags/4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(../images/flags/1x1/il.svg); }

.flag-icon-im {
  background-image: url(../images/flags/4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(../images/flags/1x1/im.svg); }

.flag-icon-in {
  background-image: url(../images/flags/4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(../images/flags/1x1/in.svg); }

.flag-icon-io {
  background-image: url(../images/flags/4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(../images/flags/1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../images/flags/4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(../images/flags/1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../images/flags/4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(../images/flags/1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../images/flags/4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(../images/flags/1x1/is.svg); }

.flag-icon-it {
  background-image: url(../images/flags/4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(../images/flags/1x1/it.svg); }

.flag-icon-je {
  background-image: url(../images/flags/4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(../images/flags/1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../images/flags/4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(../images/flags/1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../images/flags/4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(../images/flags/1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../images/flags/4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(../images/flags/1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../images/flags/4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(../images/flags/1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../images/flags/4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(../images/flags/1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../images/flags/4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(../images/flags/1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../images/flags/4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(../images/flags/1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../images/flags/4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(../images/flags/1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../images/flags/4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(../images/flags/1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../images/flags/4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(../images/flags/1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../images/flags/4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(../images/flags/1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../images/flags/4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(../images/flags/1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../images/flags/4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(../images/flags/1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../images/flags/4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(../images/flags/1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../images/flags/4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(../images/flags/1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../images/flags/4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(../images/flags/1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../images/flags/4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(../images/flags/1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../images/flags/4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(../images/flags/1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../images/flags/4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(../images/flags/1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../images/flags/4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(../images/flags/1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../images/flags/4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(../images/flags/1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../images/flags/4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(../images/flags/1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../images/flags/4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(../images/flags/1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../images/flags/4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(../images/flags/1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../images/flags/4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(../images/flags/1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../images/flags/4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(../images/flags/1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../images/flags/4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(../images/flags/1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../images/flags/4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(../images/flags/1x1/md.svg); }

.flag-icon-me {
  background-image: url(../images/flags/4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(../images/flags/1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../images/flags/4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(../images/flags/1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../images/flags/4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(../images/flags/1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../images/flags/4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(../images/flags/1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../images/flags/4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(../images/flags/1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../images/flags/4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(../images/flags/1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../images/flags/4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(../images/flags/1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../images/flags/4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(../images/flags/1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../images/flags/4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(../images/flags/1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../images/flags/4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(../images/flags/1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../images/flags/4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(../images/flags/1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../images/flags/4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(../images/flags/1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../images/flags/4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(../images/flags/1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../images/flags/4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(../images/flags/1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../images/flags/4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(../images/flags/1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../images/flags/4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(../images/flags/1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../images/flags/4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(../images/flags/1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../images/flags/4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(../images/flags/1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../images/flags/4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(../images/flags/1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../images/flags/4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(../images/flags/1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../images/flags/4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(../images/flags/1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../images/flags/4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(../images/flags/1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../images/flags/4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(../images/flags/1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../images/flags/4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(../images/flags/1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../images/flags/4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(../images/flags/1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../images/flags/4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(../images/flags/1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../images/flags/4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(../images/flags/1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../images/flags/4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(../images/flags/1x1/no.svg); }

.flag-icon-np {
  background-image: url(../images/flags/4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(../images/flags/1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../images/flags/4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(../images/flags/1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../images/flags/4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(../images/flags/1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../images/flags/4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(../images/flags/1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../images/flags/4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(../images/flags/1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../images/flags/4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(../images/flags/1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../images/flags/4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(../images/flags/1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../images/flags/4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(../images/flags/1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../images/flags/4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(../images/flags/1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../images/flags/4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(../images/flags/1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../images/flags/4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(../images/flags/1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../images/flags/4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(../images/flags/1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../images/flags/4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(../images/flags/1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../images/flags/4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(../images/flags/1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../images/flags/4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(../images/flags/1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../images/flags/4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(../images/flags/1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../images/flags/4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(../images/flags/1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../images/flags/4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(../images/flags/1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../images/flags/4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(../images/flags/1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../images/flags/4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(../images/flags/1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../images/flags/4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(../images/flags/1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../images/flags/4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(../images/flags/1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../images/flags/4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(../images/flags/1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../images/flags/4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(../images/flags/1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../images/flags/4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(../images/flags/1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../images/flags/4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(../images/flags/1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../images/flags/4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(../images/flags/1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../images/flags/4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(../images/flags/1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../images/flags/4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(../images/flags/1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../images/flags/4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(../images/flags/1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../images/flags/4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(../images/flags/1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../images/flags/4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(../images/flags/1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../images/flags/4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(../images/flags/1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../images/flags/4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(../images/flags/1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../images/flags/4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(../images/flags/1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../images/flags/4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(../images/flags/1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../images/flags/4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(../images/flags/1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../images/flags/4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(../images/flags/1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../images/flags/4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(../images/flags/1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../images/flags/4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(../images/flags/1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../images/flags/4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(../images/flags/1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../images/flags/4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(../images/flags/1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../images/flags/4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(../images/flags/1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../images/flags/4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(../images/flags/1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../images/flags/4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(../images/flags/1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../images/flags/4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(../images/flags/1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../images/flags/4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(../images/flags/1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../images/flags/4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(../images/flags/1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../images/flags/4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(../images/flags/1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../images/flags/4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(../images/flags/1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../images/flags/4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(../images/flags/1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../images/flags/4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(../images/flags/1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../images/flags/4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(../images/flags/1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../images/flags/4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(../images/flags/1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../images/flags/4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(../images/flags/1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../images/flags/4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(../images/flags/1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../images/flags/4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(../images/flags/1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../images/flags/4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(../images/flags/1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../images/flags/4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(../images/flags/1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../images/flags/4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(../images/flags/1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../images/flags/4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(../images/flags/1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../images/flags/4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(../images/flags/1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../images/flags/4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(../images/flags/1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../images/flags/4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(../images/flags/1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../images/flags/4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(../images/flags/1x1/um.svg); }

.flag-icon-us {
  background-image: url(../images/flags/4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(../images/flags/1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../images/flags/4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(../images/flags/1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../images/flags/4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(../images/flags/1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../images/flags/4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(../images/flags/1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../images/flags/4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(../images/flags/1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../images/flags/4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(../images/flags/1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../images/flags/4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(../images/flags/1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../images/flags/4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(../images/flags/1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../images/flags/4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(../images/flags/1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../images/flags/4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(../images/flags/1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../images/flags/4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(../images/flags/1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../images/flags/4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(../images/flags/1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../images/flags/4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(../images/flags/1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../images/flags/4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(../images/flags/1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../images/flags/4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(../images/flags/1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../images/flags/4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(../images/flags/1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../images/flags/4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(../images/flags/1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../images/flags/4x3/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(../images/flags/1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../images/flags/4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(../images/flags/1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../images/flags/4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../images/flags/1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../images/flags/4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(../images/flags/1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../images/flags/4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../images/flags/1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../images/flags/4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../images/flags/1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../images/flags/4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(../images/flags/1x1/un.svg); }

.fa.none {
  color: transparent !important; }

.flag-icon-en {
  background-image: url("../images/flags/4x3/gb.svg"); }

.flag-icon-da {
  background-image: url("../images/flags/4x3/dk.svg"); }

.flag-icon-nn {
  background-image: url("../images/flags/4x3/no.svg"); }

.flag-icon-ic {
  background-image: url("../images/flags/ic.svg"); }

.flag-icon-ea {
  background-image: url("../images/flags/ea.svg"); }

.flag-icon-an {
  background-image: url("../images/flags/an.svg"); }
